import { Request } from "../../Constants/api";
import { protectedAxios } from "../instances";
import { handleAPIError } from "../errorHandler";
import utils from "../../lib/utils";
import { reqTransform, resTransform } from './transforms';

export const getStoreListWithZipCode = async (zipcode) => {
  try {
    const res = await protectedAxios.get(Request.SEARCH_NEARBY_STORE, {params: {location: zipcode}})
    return resTransform(res);
  } catch (error) {
    console.log(error);
    handleAPIError(error)
  }
}