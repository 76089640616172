import axios from "axios";
import { APIConstant } from "../Constants/api";
import { APIEndpoint } from "../config/APIEndPoint";
import Storage from "../Constants/Storage";

//Instance to be used for un-authenticated resources
const openAxios = axios.create({
  baseURL: APIEndpoint,
  headers: {
    "Content-Type": APIConstant.CONTENT_TYPE,
  },
});

//Instance to be used for authenticated resources
const protectedAxios = axios.create({
  baseURL: APIEndpoint,
  headers: {
    "Content-Type": APIConstant.CONTENT_TYPE,
  },
});

// protectedAxios.interceptors.response.use((response) => {
//   console.log("Starting Request", JSON.stringify(response, null, 2));
//   return response;
// });

protectedAxios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(Storage.ACCESS_TOKEN);
    if (token != null) {
      config.headers.authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

openAxios.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

protectedAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401) {
    }else{
      throw error
    }
  }
);

export { openAxios, protectedAxios }
