import { createTheme } from "@mui/material/styles";
import { Colors } from "./Constants/Colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.FERRARI_RED,
    },
    secondary: {
      main: Colors.RED_LIGHT,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "MonserratRegular",
      color: Colors.BLACK,
    },
    h1: {
      fontFamily: 'MonserratRegular',
      fontWeight: '500'
    },
    h2: {
      fontFamily: 'MonserratRegular',
      fontWeight: '500'
    },
    h3: {
      fontFamily: 'MonserratRegular',
      fontWeight: '500'
    },
    h4: {
      fontFamily: 'MonserratRegular',
      fontWeight: '600'
    },
    h5: {
      fontFamily: 'MonserratRegular',
      fontWeight: '500'
    },
    h6: {
      fontFamily: 'MonserratRegular',
      fontWeight: '500'
    },
    button: {
      color: Colors.WHITE,
      textTransform: "none",
    },
  },
});
