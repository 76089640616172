import { createSlice  } from "@reduxjs/toolkit";

export const auth = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: null,
    isLoading: false,
    showRegistrationSuccess: false,
    user: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setIsAuthenticated(state, action) {
      return { ...state, isAuthenticated: action.payload };
    },
    setIsLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    setShowRegistrationSuccess(state, action) {
      return { ...state, showRegistrationSuccess: action.payload };
    },
    setLogout(state, action) {
      state.isAuthenticated = false
      state.user = null
    },
    resetState(state, action){}
  },
});

const authActions = {
  ...auth.actions,
};

export { authActions };

export default auth.reducer;
