import { createSlice } from "@reduxjs/toolkit";

export const filters = createSlice({
  name: 'filters',
  initialState: {
    appliedFilters: [],
    page: 1
  },
  reducers: {
    setAppliedFilterts(state, action) {
      return {...state, appliedFilters: action.payload}
    },
    setPage(state, action){
      return {...state, page: action.payload}
    }
  }
})

const appliedFiltersAction = {
  ...filters.actions
}

export {appliedFiltersAction}

export default filters.reducer