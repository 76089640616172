import _ from "lodash";
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {getCarList} from "../../Services/carListService/carListService";


const carListAdapter = createEntityAdapter({});

const carListSlice = createSlice({
  name: 'carList',
  initialState: carListAdapter.getInitialState({
    busy: false,
    count: 0,
    next: "",
    previous: ""
  }),
  reducers: {
    setAll: carListAdapter.setAll,
    addOne: carListAdapter.addOne,
    addMany: carListAdapter.addMany,
    updateOne: carListAdapter.updateOne,
    updateMany: carListAdapter.updateMany,
    upsertOne: carListAdapter.upsertOne,
    upsertMany: carListAdapter.upsertMany,
    removeOne: carListAdapter.removeOne,
    removeMany: carListAdapter.removeMany,
    removeAll: carListAdapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
    setCount(state, action){
        state.count = action.payload;
    },
    setPrevious(state, action){
        state.previous = action.payload;
    },
    setNext(state, action){
        state.next = action.payload;
    }
  },
});

// fetching carlist data here
const fetchList = (params, searchParams) => async (dispatch) => {
  dispatch(carListSlice.actions.setBusy(true));
  try {
    const resData = await getCarList(params);
    const count = _.get(resData, 'count') || 0;    //getting data using loadash
    const carList = _.get(resData, 'results') || [];
    const next = _.get(resData, "next") || null;
    const previous = _.get(resData, "previous") || null; //--------------------
    dispatch(carListSlice.actions.setCount(count));
    dispatch(carListSlice.actions.setNext(next));
    dispatch(carListSlice.actions.setPrevious(previous));
    dispatch(carListSlice.actions.setAll(carList));
  } catch (err) {
    dispatch(carListSlice.actions.setAll({}));
    dispatch(carListSlice.actions.setCount(0));
  }
  dispatch(carListSlice.actions.setBusy(false));
};

const actions = {
  ...carListSlice.actions,
  fetchList,
};

const selectors = {
  ...carListAdapter.getSelectors((state) => state.carList),
};

export { actions, selectors };

export default carListSlice.reducer;
