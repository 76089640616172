import { Request } from "../../Constants/api";
import { protectedAxios } from "../instances";
import { handleAPIError } from "../errorHandler";
import utils from "../../lib/utils";

export const getCarDetails = async (params) => {
  try {
    // console.log("calling details fetch");
    const res = await protectedAxios.get(`${Request.CARS}?id=${params}`);
    const resData = await utils.processApiRes(res);
    // console.log(resData);
    return resData;
  } catch (err) {
    console.log(err);
    handleAPIError(err);
  }
};
export const getCarVariants = async (params) => {
  try {
    const res = await protectedAxios.get(`${Request.CARS}?variant=${params}`);
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log(err);
    handleAPIError(err);
  }
};
export const getUserReviews = async (id, setUserReviews) => {
  try {
    const res = await protectedAxios.get(
      `${Request.USER_REVIEWS}?feedback_car=${id}`
    );
    const resData = await utils.processApiRes(res);
    if (resData) {
      setUserReviews(resData);
    }
  } catch (err) {
    console.log(err);
    handleAPIError(err);
  }
};
export const postUserReviews = async (params) => {
  params.setIsLoading(true);
  const data = {
    ratings: params.ratings,
    comment: params.comment,
    feedback_car: params.id,
  };
  try {
    if (
      data.ratings &&
      data.comment &&
      data.feedback_car
    ) {
      const res = await protectedAxios.post(Request.USER_REVIEWS, data);
      const resData = await utils.processApiRes(res);
      params.setIsLoading(false);
      return resData;
    }
  } catch (err) {
    console.log("error in posting reviews", err);
    handleAPIError(err);
    params.setIsLoading(false);
  }
};
