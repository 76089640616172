import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {getUserLocation, getStoreByZipCode, getStoreByState} from "../../Services/changeStoreService/index";
import { handleAPIError } from "../../Services/errorHandler";
import { Request } from "../../Constants/api";
import { protectedAxios } from "../../Services/instances";
import utils from "../../lib/utils";


const storeChangeAdapter = createEntityAdapter({});


const storeChangeSlice = createSlice({

    name: "storeChange",
    initialState: {
        zipBusy: false,
        myLocationBusy: false,
        stateBusy: false,

        myLocationStores: [],
        zipStores: [],
        stateStores: [],
        allStates: [],

        userStore: []

    },
    reducers:{

        setZipStores(state, action){
            state.zipStores = action.payload;
        },
        setMyLocationStores(state, action){
            state.myLocationStores = action.payload;
        },
        setStateStores(state, action){
            state.stateStores = action.payload;
        },
        setAllStates(state, action){
            state.allStates = action.payload;
        },
        setUserStore(state, action){
            state.userStore = action.payload;
        },
        setZipBusy(state, action) {
            let busy = action.payload;
            if (busy === false) {
              state.zipBusy = false;
            } else {
              state.zipBusy = true;
            }
          },
          setMyLocationBusy(state, action){
            let busy = action.payload;
            if(busy === false){
                state.myLocationBusy = false
            } else {
                state.myLocationBusy = true;
            }
          },
          setStateBusy(state, action){
            let busy = action.payload;
            if(busy === false){
                state.stateBusy = false
            } else {
                state.stateBusy = true;
            }
          }

    }
});


export const fetchUserLocation = async ()=>{

    try{
        const res = await protectedAxios.get("https://ipapi.co/json");
        const resData = await utils.processApiRes(res);
        return resData;
    }catch(err){
        console.log("Error : ", err);
    }

}

const fetchStoreByZip = (params) =>async (dispatch) =>{
        dispatch(storeChangeSlice.actions.setZipBusy(true));
        try {
            // const res = await protectedAxios.get(Request.GET_ZIP_CODE + `/${params.latitude}/${params.longitude}`);
            const res = await protectedAxios.get(Request.GET_ZIP_CODE + `/${18.4806446},${73.9466214}`);
            const resData = await utils.processApiRes(res);
            dispatch(storeChangeSlice.actions.setZipStores(resData));
            dispatch(storeChangeSlice.actions.setZipBusy(false));
            return res.data;
          } catch (error) {
            console.log(error);
            handleAPIError(error)
            dispatch(storeChangeSlice.actions.setZipBusy(false));
          }
        dispatch(storeChangeSlice.actions.setZipBusy(false));
}


const fetchStoreByState = (params) => async(dispatch)=>{
    dispatch(storeChangeSlice.actions.setStateBusy(true));
    try{
        
        const res = await protectedAxios.get(`${Request.STORE_BY_STATES}?state=${params}`);
        const resData = await utils.processApiRes(res);
        dispatch(storeChangeSlice.actions.setStateStores(resData));
        dispatch(storeChangeSlice.actions.setStateBusy(false));
        return resData;
    }catch(err){
        
        console.log("Error:", err);
        dispatch(storeChangeSlice.actions.setStateBusy(false));
        
    }
    dispatch(storeChangeSlice.actions.setStateBusy(false));

}


const fetchStoreByLocation = (params) => async(dispatch)=>{
    dispatch(storeChangeSlice.actions.setMyLocationBusy(true));
    try{
        
        const res = await protectedAxios.get(`${Request.SEARCH_NEARBY_STORE}?location=${params.lat},${params.lon}`);
        const resData = await utils.processApiRes(res);
        dispatch(storeChangeSlice.actions.setMyLocationStores(resData));
        dispatch(storeChangeSlice.actions.setMyLocationBusy(false));
        return resData;
    }catch(err){
        
        console.log("Error:", err);
        dispatch(storeChangeSlice.actions.setMyLocationBusy(false));
        
    }
    dispatch(storeChangeSlice.actions.setMyLocationBusy(false));

}



export const fetchAllStates = () => async (dispatch)=>{
    try{
        const res =  await protectedAxios.get(Request.STORE_STATES);
        const resData = await utils.processApiRes(res);
        dispatch(storeChangeSlice.actions.setAllStates(resData));
        return resData;
    }catch(err)
    {
        console.log(err);
        handleAPIError(err);
    }

}

const actions = { 
    ...storeChangeSlice.actions,
    fetchStoreByState,
    fetchStoreByZip,
    fetchStoreByLocation,
    fetchAllStates
}

const selectors = {
    ...storeChangeAdapter.getSelectors((state) => state.storeChnage)
};

export {actions, selectors};

export default storeChangeSlice.reducer;

