import { combineReducers, configureStore } from "@reduxjs/toolkit";
import auth from './slice/authSlice'
import error from './slice/errorSlice'
import user from './slice/userSlice'
import masterData from './slice/masterDataSlice'
import filters from './slice/filterSlice'
import car from "./slice/carListSlice";
import carModels from './slice/carModels';
import favorites from './slice/favorites';
import carDetail from "./slice/carDetailsSlice";
import stateSlice from "./slice/stateSlice";
import postalSlice from "./slice/postalSlice";
import storeChange from "./slice/storeChange";

const combinedReducer = combineReducers({
  auth,
  error,
  user,
  masterData,
  filters,
  car,
  carModels,
  favorites,
  carDetail,
  stateSlice,
  postalSlice,
  storeChange
}) 

const rootReducer = (state, action) => {
  if(action.type === "auth/resetState"){
    state = undefined
  }
  return combinedReducer(state, action)
}

export default configureStore({
  reducer: rootReducer
});