import { Request } from "../../Constants/api";
import { protectedAxios } from "../instances";
import { handleAPIError } from "../errorHandler";
import utils from "../../lib/utils";

export const getFavoritesCars = async () => {
  try {
    const res = await protectedAxios.get(`${Request.ADD_CAR_TO_FAVORITE}`);
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (error) {
    console.log(error);
    handleAPIError(error);
  }
};


