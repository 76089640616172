import { createSlice } from "@reduxjs/toolkit";

export const error = createSlice({
  name: 'error',
  initialState: {
    error: {}
  },
  reducers: {
    showError(state, action) {
      return {...state, error: action.payload}
    },
    hideError() {
      return {error: {}}
    }
  }
})

const errorActions = {
  ...error.actions
}

export {errorActions}

export default error.reducer