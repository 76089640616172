import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { errorActions } from "../../redux/slice/errorSlice";
import store from "../../redux/store";
import AlertBar from "./AlertBar";

const ErrorAlert = () => {
  const error = useSelector((state) => state.error.error);
  return (
    <>
      {!_.isEmpty(error) && (
        <AlertBar
          title="Error"
          message={error.message}
          onClose={() => store.dispatch(errorActions.hideError())}
          AlertProps={{
            severity: 'error'
          }}
        />
      )}
    </>
  );
};

export default ErrorAlert;
