import React from "react";
import { registerUser, userLogin } from "../Services/authServices";
import store from "../redux/store";
import { authActions } from "../redux/slice/authSlice";
import Storage from "../Constants/Storage";
import { openAxios } from "../Services/instances";
import { Request } from "../Constants/api";
import utils, { getItem } from "../lib/utils";
import _ from "lodash";

const ereaseToken = async () => {
  utils.logout();
  store.dispatch(authActions.setIsAuthenticated(false));
  window.location.reload(false);
};

const setRefreshTokenTimer = (delay) => {
  let refreshTimeOutId;
  refreshTimeOutId = setTimeout(getRefreshedToken, (delay - 60) * 1000); // Validity period of the token in seconds, minus 1 min
};

const setLocalAuthentication = (data) => {
  let currentDateTime = new Date();
  localStorage.setItem(Storage.ACCESS_TOKEN, data.access);
  localStorage.setItem(Storage.REFRESH_TOKEN, data.refresh);
  localStorage.setItem(
    Storage.ACCESS_EXPIRY,
    new Date(currentDateTime.getTime() + parseInt(data.access_expiry) * 1000)
  );
  localStorage.setItem(
    Storage.REFRESH_EXPIRY,
    new Date(currentDateTime.getTime() + parseInt(data.refresh_expiry) * 1000)
  );
  setRefreshTokenTimer(data.access_expiry);
};

export const getRefreshedToken = async () => {
  let refreshToken = await localStorage.getItem(Storage.REFRESH_TOKEN);
  if (!refreshToken) {
    ereaseToken();
    return false;
  }
  try {
    let isRefreshing = await openAxios.post(Request.RENEW_ACCESS_TOKEN, {
      refresh: refreshToken,
    });
    let res = await isRefreshing;
    const data = await utils.processApiRes(res);
    if (data) {
      await setLocalAuthentication(data);
      store.dispatch(authActions.setIsAuthenticated(true));
    }
  } catch (err) {
    console.log("errr------->", err);
    // window.location.reload(false);
  }
};

export const loginViewModel = async (values) => {
  try {
    store.dispatch(authActions.setIsLoading(true));
    const data = await userLogin(values);
    if (data) {
      setLocalAuthentication(data);
      store.dispatch(authActions.setIsAuthenticated(true));
    }
    store.dispatch(authActions.setIsLoading(false));
    return data;
  } catch (error) {
    console.log(error);
    store.dispatch(authActions.setIsLoading(false));
  }
};

export const registerViewModel = async (values) => {
  const registerData = {
    email: values.email,
    password: values.password,
    first_name: values.first_name,
    last_name: values.last_name,
    phone: values.phone,
  };
  try {
    store.dispatch(authActions.setIsLoading(true));
    const data = await registerUser(registerData);
    if (data) {
      store.dispatch(authActions.setShowRegistrationSuccess(true));
      const fcm_token = await getItem(Storage.FCM_TOKEN);
      const loginData = {
        email: registerData.email,
        password: registerData.password,
        fcm_token: fcm_token || "",
        type: "web",
      };
      const response = await loginViewModel(loginData);
      if (!_.isEmpty(response)) {
        values.navigate("/");
      } else {
        store.dispatch(authActions.setIsLoading(false));
      }
    }
    store.dispatch(authActions.setIsLoading(false));
  } catch (error) {
    console.log(error);
    store.dispatch(authActions.setIsLoading(false));
  }
};
