const Storage = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  USER: "user",
  FCM_TOKEN: "fcmToken",
  ACCESS_EXPIRY: "access_expiry",
  REFRESH_EXPIRY: "refresh_expiry",
  LOGGED_IN_TIME: "logged_in_time",
  IS_LOGGED_IN_AS_GUEST: "isLoggedInAsGuest",
  STORE_SELECTED_BY_GUEST: "storeSelectedByGuest",
  GUEST_APPOINTMENT_DETAILS: "guestAppointmentDetails",
  SHOW_CAR_DETAILS_APP_TOUR: "showCarDetailsAppTour",
  IS_USER_ONBOARDED: "isUserOnboarded",
  HAS_STORE_SELECTION_SHOWN: 'hasStoreSelectionShown',
  RENEW_ACCESS_TOKEN_TIME : 'renew_access_token_time'
}

export default Storage