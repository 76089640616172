export const Colors = {
  FERRARI_RED: '#FF2800',
  RED_LIGHT: '#ef5350',
  WHITE: '#ffffff',
  BLACK: '#000',
  FERRARI_RED_DARK: '#7a210d',
  GRAY: '#616161'
}

export const FontStyle = {
  NissanBold: 'NissanBold',
  NissanRegular: 'NissanRegular',
  MonserratBold: 'MonserratBold',
  MonserratRegular: 'MonserratRegular'
}