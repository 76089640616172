import _ from 'lodash';
import { errorActions } from '../redux/slice/errorSlice';
import store from '../redux/store';

export const handleAPIError = (errorValue = {}) => {
  try{
    const statusCode = errorValue?.response?.status;
    
    let defaultErrorDialog = {
      message: 'Something went wrong',
      title: 'Atención',
      positiveButton: 'Continuar',
      negativeButton: 'Cancelar',
    }
    let errorDialog = {}
    
    switch(statusCode){
      case 500:
        const errorMessage = _.isObject(errorValue?.response?.data?.message) ? 
          Object.values(errorValue?.response?.data?.message).join('\n') : 
          errorValue?.response?.data?.message
        errorDialog = {...defaultErrorDialog, message: errorMessage}
        break;
      case 400:
        const error = _.isObject(errorValue?.response?.data?.message) ? 
          Object.values(errorValue?.response?.data?.message).join('\n') : 
          errorValue?.response?.data?.message
        errorDialog = {...defaultErrorDialog, message: error}
        break;
      case 404:
        errorDialog = {...defaultErrorDialog, message: 'Requested resource not found'}
        break; 
        case 13:
        errorDialog ={...defaultErrorDialog, message: 'Por favor sube imágenes'}
        break;     
      default:
        errorDialog = {...defaultErrorDialog};
    } 
    store.dispatch(errorActions.showError(errorDialog));
  }catch(err){
    const errorDialog = 'Something went wrong.Please try again'
    store.dispatch(errorActions.showError(errorDialog));
  }
}