const APIConstant = {
  CONTENT_TYPE: "application/json",
};

const URLConstant = {};

const PathPram = {};

const Request = {
  REGISTER: "/user/",
  FORGOT_PASSWORD: "/password/forgot",
  RESET_PASSWORD: "/password/reset",
  LOGIN: "/token/",
  GET_USER_INFO: "/user/",
  LOGOUT: "/token/logout/",
  CARS: "/car/",
  CAR_MAKES: "/ref/car/make",
  CAR_TYPES: "/ref/car/type",
  CAR_MODELS: "/ref/car/model",
  RENEW_ACCESS_TOKEN: "/token/refresh/",
  CAR_VARIANTS: "/ref/car/variant",
  ALL_CAR_FEATURES: "/ref/car/feature",
  RECENT_SEARCHES: "/user/car/search",
  DELETE_RECENT_SEARCH: "/user/car/search",
  SEARCH_NEARBY_STORE: "/ref/store/nearby",
  CAR_CYLINDERS_MASTER: "/ref/car/cylinder",
  CAR_DRIVE_MASTER: "/ref/car/drive",
  CAR_SIZE_MASTER: "/ref/car/size",
  CAR_TRANSMISSION_MASTER: "/ref/car/transmission",
  STORE_STATES: "/ref/state",
  STORE_BY_STATES: "/ref/store",
  ADD_CAR_TO_FAVORITE: "/user/car/favorite",
  STORE_EMAIL: "/user/store/email",
  STORE_APPOINTMENT: "/user/store/appointment",
  CAR_LOAN: "/user/car/loan",
  CAR_OFFER: "/offer",
  CAR_COLOR: "/ref/car/color",
  FINANCE_INFO: "/user/finance/info",
  ORDERS: "/user/car/order",
  FEEDBACK: "/user/feedback",
  USER_PREFERENCE: "/user/preference",
  CAR_STORE: "/ref/car/store",
  FAQ: "/faq",
  MAKE_PAYMENT: "/user/car/payment",
  ADDRESS: "/user/address",
  CITY: "/ref/city",
  CAR_PRICE: "/user/car/price-range",
  // FORGOT_PASSWORD: "/password​/forgot"
  VERIFY_POSTAL_CODE: "/ref/postal",
  GET_SEARCHABLE_LIST: "/get_searchable_list",
  GET_ZIP_CODE: "/get_zip_code",
  GET_CREDIT_CALCULATION: '/credit_calculation',
  DELETE_USER_ACCOUNT: '/user/deactivate',
  USER_REVIEWS: '/user/reviews',
};

export { APIConstant, PathPram, Request, URLConstant };
