import { Request } from "../../Constants/api";
import { protectedAxios } from "../instances";
import { handleAPIError } from "../errorHandler";
import utils from "../../lib/utils";

export const getCarList = async (params, handleReduxState) => {
  try {
    let queryParams = utils.getQs(params);

    const res = await protectedAxios.get(`${Request.CARS}?${queryParams}`);
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (error) {
    console.log(error);
    handleAPIError(error);
  }
};
