import { Request } from "../../Constants/api";
import { protectedAxios } from "../instances";
import { handleAPIError } from "../errorHandler";
import utils from "../../lib/utils";
import _ from "lodash";

export const getCarMakeModelList = async (makeId, handleReduxState) => {
  try {
    const res = await protectedAxios.get(
      `${Request.CAR_MODELS}?make=${makeId}`
    );
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (error) {
    console.log(error);
    handleAPIError(error);
  }
};

export const getCarVariants = async (modelId) => {
  try {
    const res = await protectedAxios.get(
      `${Request.CAR_VARIANTS}?model=${modelId}`
    );
    // return res.data;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (error) {
    console.log(error, "error while getting variants");
  }
};

export const addCarData = async (params, success) => {
  // console.log("success:", success())
  const totalCarData = {
    car_variant: params?.variantId,
    year: params?.year,
    mileage: params?.kilometer,
    // car_feature: features,
    owner_email: params?.email || null,
    owner_phone: params?.phone || null,
    // car_image_front: !_.isEmpty(params?.front_side) || null,
    car_image_front: params?.front_side ? params?.front_side[0] : null,
    car_image_back: params?.rear_side ? params?.rear_side[0] : null,
    car_image_front_right: params?.right_side ? params?.right_side[0] : null,
    car_image_front_left: params?.left_side ? params?.left_side[0] : null,
    // car_image_internal: `data:${images.carInteriorImage.mime};base64,${images.carInteriorImage.data}`,
    // car_image_motor: `data:${images.carMotorImage.mime};base64,${images.carMotorImage.data}`,
    // car_image_dashboard: !_.isEmpty(params?.dashboard) || null,
    // car_image_back_left: `data:${images.carBackLeftImage.mime};base64,${images.carBackLeftImage.data}`,
    // car_image_back_right: `data:${images.carBackRightImage.mime};base64,${images.carBackRightImage.data}`,
    // car_image_meter: `data:${images.carMeterImage.mime};base64,${images.carMeterImage.data}`,
    // car_image_headroom: `data:${images.carHeadroomImage.mime};base64,${images.carHeadroomImage.data}`,
    // car_image_boot_space: `data:${images.carBootSpaceImage.mime};base64,${images.carBootSpaceImage.data}`,
    // user_car_type: carType,
    // car_drive: carDrive,
    car_transmission: params?.transmission,
    store: params?.store ? params?.store : 1,
    // car_questions: [...mileageQuestions, modifications, selectedCondition],
    vin_number: params?.vin,
    // license_plate: params?.license,
    // zip_code: params?.zipCode,
  };

  try {
    if (
      (totalCarData.car_image_front ||
        totalCarData.car_image_back ||
        totalCarData.car_image_front_left ||
        totalCarData.car_image_front_right) !== null
    ) {
      const res = await protectedAxios.post(Request.CARS, totalCarData);
      const resData = await utils.processApiRes(res);
      success();
      return resData;
    } else {
      handleAPIError({
        response: {
          status: 13,
        },
      });
    }
  } catch (error) {
    handleAPIError(error);
    throw error;
  }
};
