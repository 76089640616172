import _ from 'lodash';
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getFavoritesCars } from "../../Services/favorites";

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: "favorites",
  initialState: adapter.getInitialState({
    busy: false,
    count: 0,
    next: "",
    previous: "",
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setNext(state, action) {
      state.next = action.payload;
    },
    setPrevious(state, action) {
      state.previous = action.payload;
    },
  },
});

const fetchList = () => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await getFavoritesCars();
    const count = _.get(resData, 'count') || 0;
    dispatch(slice.actions.setCount(count));
    const next = _.get(resData, 'next') || '';
    dispatch(slice.actions.setNext(next));
    const previous = _.get(resData, 'previous') || '';
    dispatch(slice.actions.setPrevious(previous));
    const results = _.get(resData, 'results') || [];
    dispatch(slice.actions.setAll(results));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    dispatch(slice.actions.setCount(0));
    dispatch(slice.actions.setNext(''));
    dispatch(slice.actions.setPrevious(''));
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchList,
};

const selectors = {
  ...adapter.getSelectors((state) => state.favorites),
};

export { actions, selectors };

export default slice.reducer;
