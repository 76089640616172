import { Request } from '../Constants/api'
import { handleAPIError } from './errorHandler'
import {openAxios} from './instances'

export const userLogin = async (values) => {
  try {
    const res = await openAxios.post(Request.LOGIN, values)
    return res.data
  } catch (error) {
    console.log(error);
    handleAPIError(error)
  }
}

export const registerUser = async (values) => {
  try {
    const res = await openAxios.post(Request.REGISTER, values)
    return res.data
  } catch (error) {
    console.log(error);
    handleAPIError(error)
  }
}