import _ from "lodash";
import normalize from "normalize-object";
import Storage from "../Constants/Storage";
import store from "../redux/store";
import { authActions } from "../redux/slice/authSlice";
import { getRefreshedToken } from "../ViewModels/AuthViewModel";

const utils = {
  async processApiRes(res = {}) {
    let resJSON = res;
    if (typeof res.json === "function") {
      resJSON = await utils.getResJSON(res);
    }

    const resData = _.get(resJSON, "data", {}) || {};
    let snakeRes = normalize(resData, "snake");
    return snakeRes;
  },

  formatPrice(price) {
    return price.toLocaleString("en-US");
  },
  getQs(params = {}) {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
  },
  getFilters(filters) {
    let filterParams = {};
    _.forOwn(filters, function (val, key) {
      if (val !== undefined) {
        filterParams = {
          ...filterParams,
          [key]: val,
        };
      }
    });
    return filterParams;
  },
  logout() {
    localStorage.removeItem(Storage.ACCESS_TOKEN);
    localStorage.removeItem(Storage.REFRESH_TOKEN);
    localStorage.removeItem(Storage.ACCESS_EXPIRY);
    localStorage.removeItem(Storage.REFRESH_EXPIRY);
    localStorage.removeItem("makeName");
    localStorage.removeItem("modelName");
    localStorage.removeItem("uri");
    localStorage.removeItem("historySearch");
  },

  async checkToken() {
    const accessToken = localStorage.getItem(Storage.ACCESS_TOKEN);
    const refreshTokenExpiry = new Date(
      localStorage.getItem(Storage.REFRESH_EXPIRY)
    );
    let accessTokenExpiry = new Date(
      localStorage.getItem(Storage.ACCESS_EXPIRY)
    );
    let currentDateTime = new Date();
    if (!_.isNull(accessToken)) {
      if (accessTokenExpiry <= currentDateTime) {
        if (refreshTokenExpiry <= currentDateTime) {
          utils.logout();
          store.dispatch(authActions.setIsAuthenticated(false));
        } else {
          await getRefreshedToken();
        }
      } else if (accessTokenExpiry > currentDateTime) {
        store.dispatch(authActions.setIsAuthenticated(true));
      }
    } else {
      store.dispatch(authActions.setIsAuthenticated(false));
    }
  },
};

export default utils;

export const setItem = (key, value) => {
  return localStorage.setItem(key, value);
};

export const getItem = (key) => {
  return localStorage.getItem(key);
};

export const removeItem = (key) => {
  return localStorage.removeItem(key);
};
