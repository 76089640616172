import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
// import Authentication from "./Pages/Authentication";
// import CarDetails from "./Pages/CarDetails";
// import Cars from "./Pages/Cars";
// // import Home from "./Pages/Home";
// import Profile from "./Pages/Profile";
// import Root from "./Pages/Root";
// import AboutUs from "./Pages/AboutUs/index";
// import ContactUs from "./Pages/ContactUs/index";
// import WorkWithUs from "./Pages/WorkWithUs/index";
// import WriteYourReview from "./Pages/WriteYourReview/index";
// import FavoriteList from "./Views/PostAuthViews/Favorite";
// import Finance from "./Pages/Finance";
// import MyAccount from "./Pages/MyAccount";
// import ProfileStore from "./Pages/MyAccount/ChangeStore/index";
// import SellYourCar from "./Pages/sellYourCar";
// import LoginModal from "./common/RegisterationModal";
// import Appointment from "./Views/PostAuthViews/ProfileViews/ProfileOptionDetails/components/Appointment";
// import CanIAffordIt from "./Views/PostAuthViews/CarDetailsViews_new/components/CanIAffordIt";
// import FAQPage from "./Pages/FAQ/FAQPage";
// import CreditForm from "./Views/PostAuthViews/CarDetailsViews_new/components/CreditForm";
// import FeedBackForm from "./Pages/FeedBack/FeedBackForm";
// import Orders from "./Pages/Orders/Orders";
// import AddressForm from "./Pages/Orders/AddressForm";
// import WebViewScreen from "./Views/PostAuthViews/Payment/WebViewScreen";
// import PaymentSuccess from "./Views/PostAuthViews/Payment/PaymentSuccess";
// import PaymentFailure from "./Views/PostAuthViews/Payment/PaymentFailure";
// import Offers from "./Views/PostAuthViews/HomeViews/promociones";
// import PaymentCalculator from "./Views/PostAuthViews/HomeViews/FINANCIAMIENTO/PaymentCalculator";
// import AGENDATUCITA from "./Views/PostAuthViews/HomeViews/SERVICIO_REFACCIONES/AGENDATUCITA";
// import ServiceSpecials from "./Views/PostAuthViews/HomeViews/promociones/ServiceSpecials";
// import WalletPass from "./Views/PostAuthViews/HomeViews/promociones/WalletPass";
// import ApplyForFinancing from "./Views/PostAuthViews/HomeViews/FINANCIAMIENTO/ApplyForFinancing";
// import ServicePage from "./Views/PostAuthViews/HomeViews/promociones/ServicePage";
// import PrivacyPolicy from "./Views/SharedViews/footer/PrivacyPolicy";
// import TermsOfUse from "./Views/SharedViews/footer/TermsOfUse";
// import OrderParts from "./Views/PostAuthViews/HomeViews/SERVICIO_REFACCIONES/OrderParts";
// import PartsDepartment from "./Views/PostAuthViews/HomeViews/SERVICIO_REFACCIONES/PartsDepartment";
// import loadable from "@loadable/component"

// const Root = loadable(() => import("./Pages/Root"));
import loadable from "@loadable/component";
const Authentication = loadable(() => import("./Pages/Authentication"));
const CarDetails = loadable(() => import("./Pages/CarDetails"));
const Cars = loadable(() => import("./Pages/Cars"));
const Home = loadable(() => import("./Pages/Home"));
const Profile = loadable(() => import("./Pages/Profile"));
const Root = loadable(() => import("./Pages/Root"));
const AboutUs = loadable(() => import("./Pages/AboutUs/index"));
const ContactUs = loadable(() => import("./Pages/ContactUs/index"));
const WorkWithUs = loadable(() => import("./Pages/WorkWithUs/index"));
const WriteYourReview = loadable(() => import("./Pages/WriteYourReview/index"));
const FavoriteList = loadable(() => import("./Views/PostAuthViews/Favorite"));
const Finance = loadable(() => import("./Pages/Finance"));
const MyAccount = loadable(() => import("./Pages/MyAccount"));
// const ProfileStore = loadable(() =>
//   import("./Pages/MyAccount/ChangeStore/index")
// );
const SellYourCar = loadable(() => import("./Pages/sellYourCar"));
const LoginModal = loadable(() => import("./common/RegisterationModal"));
const Appointment = loadable(() =>
  import(
    "./Views/PostAuthViews/ProfileViews/ProfileOptionDetails/components/Appointment"
  )
);
const CanIAffordIt = loadable(() =>
  import("./Views/PostAuthViews/CarDetailsViews_new/components/CanIAffordIt")
);
const FAQPage = loadable(() => import("./Pages/FAQ/FAQPage"));
const CreditForm = loadable(() =>
  import("./Views/PostAuthViews/CarDetailsViews_new/components/CreditForm")
);
const FeedBackForm = loadable(() => import("./Pages/FeedBack/FeedBackForm"));
const Orders = loadable(() => import("./Pages/Orders/Orders"));
const AddressForm = loadable(() => import("./Pages/Orders/AddressForm"));
const WebViewScreen = loadable(() =>
  import("./Views/PostAuthViews/Payment/WebViewScreen")
);
const PaymentSuccess = loadable(() =>
  import("./Views/PostAuthViews/Payment/PaymentSuccess")
);
const PaymentFailure = loadable(() =>
  import("./Views/PostAuthViews/Payment/PaymentFailure")
);
const Offers = loadable(() =>
  import("./Views/PostAuthViews/HomeViews/promociones")
);
const PaymentCalculator = loadable(() =>
  import("./Views/PostAuthViews/HomeViews/FINANCIAMIENTO/PaymentCalculator")
);
const AGENDATUCITA = loadable(() =>
  import("./Views/PostAuthViews/HomeViews/SERVICIO_REFACCIONES/AGENDATUCITA")
);
const ServiceSpecials = loadable(() =>
  import("./Views/PostAuthViews/HomeViews/promociones/ServiceSpecials")
);
const WalletPass = loadable(() =>
  import("./Views/PostAuthViews/HomeViews/promociones/WalletPass")
);
const ApplyForFinancing = loadable(() =>
  import("./Views/PostAuthViews/HomeViews/FINANCIAMIENTO/ApplyForFinancing")
);
const ServicePage = loadable(() =>
  import("./Views/PostAuthViews/HomeViews/promociones/ServicePage")
);
const PrivacyPolicy = loadable(() =>
  import("./Views/SharedViews/footer/PrivacyPolicy")
);
const TermsOfUse = loadable(() =>
  import("./Views/SharedViews/footer/TermsOfUse")
);
const OrderParts = loadable(() =>
  import("./Views/PostAuthViews/HomeViews/SERVICIO_REFACCIONES/OrderParts")
);
const PartsDepartment = loadable(() =>
  import("./Views/PostAuthViews/HomeViews/SERVICIO_REFACCIONES/PartsDepartment")
);

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/myTrustyCar/:option",
        element: <Profile />,
      },
      {
        path: "/car/:id",
        element: <CarDetails />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/work-with-us",
        element: <WorkWithUs />,
      },
      {
        path: "/write-your-review",
        element: <WriteYourReview />,
      },
      {
        path: "/saved-cars",
        element: <FavoriteList />,
      },
      {
        path: "/cars",
        element: <Cars />,
      },
      {
        path: "/finance",
        element: <Finance />,
      },
      {
        path: "/sell-your-car",
        element: <SellYourCar />,
      },
      {
        path: "/my-account",
        element: <MyAccount />,
      },
      // {
      //   path: "/my-account/store",
      //   element: <ProfileStore />,
      // },
      {
        path: "/appointment",
        element: <Appointment />,
      },
      {
        path: "/credit",
        element: <CanIAffordIt />,
      },
      {
        path: "/faq",
        element: <FAQPage />,
      },
      {
        path: "/credit-form",
        element: <CreditForm />,
      },
      {
        path: "/feedback-form",
        element: <FeedBackForm />,
      },
      {
        path: "/orders",
        element: <Orders />,
      },
      {
        path: "/address",
        element: <AddressForm />,
      },
      {
        path: "/WebViewScreen",
        element: <WebViewScreen />,
      },
      {
        path: "/payment_success",
        element: <PaymentSuccess />,
      },
      {
        path: "/payment_fail",
        element: <PaymentFailure />,
      },
      {
        path: "/view-offers",
        element: <Offers />,
      },
      {
        path: "/payment-calculator",
        element: <PaymentCalculator />,
      },
      {
        path: "/schedule-service",
        element: <AGENDATUCITA />,
      },
      {
        path: "/service-specials",
        element: <ServiceSpecials />,
      },
      {
        path: "/wallet-pass",
        element: <WalletPass />,
      },
      {
        path: "/apply-for-financing",
        element: <ApplyForFinancing />,
      },
      {
        path: "/service",
        element: <ServicePage />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsOfUse />,
      },
      {
        path: "/order-parts",
        element: <OrderParts />,
      },
      {
        path: "/parts",
        element: <PartsDepartment />,
      },
    ],
  },
  {
    path: "/auth",
    element: <Authentication />,
  },
  {
    path: "/login",
    element: <LoginModal />,
  },
]);
