import { Request } from "../../Constants/api";
import { protectedAxios } from "../instances";
import { handleAPIError } from "../errorHandler";
import utils from "../../lib/utils";
import { reqTransform, resTransform } from './transforms';

export const getStatesList = async () => {
  try {
    const res = await protectedAxios.get(Request.STORE_STATES)
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (error) {
    console.log(error);
    handleAPIError(error)
  }
}