import _ from "lodash";

export const reqTransform = {};

export const resTransform = {
  fetchList(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(data, "id");
  },
  fetchDetails(resData) {
    return {
      ...resData,
      value: _.get(resData, "id") || 0,
      label: _.get(resData, "name") || '',
    };
  },
};
