import { createSlice } from "@reduxjs/toolkit";

export const masterData = createSlice({
  name: 'masterData',
  initialState: {
    loadingFor: '',
    price: [],
    year: [],
    mileage: [],
    make: [],
    model: [],
    features: [],
    exteriorColors: [],
    interiorColors: [],
    type: [],
    transmissionType: [],
    drive: [],
    cylinders: [],
    size: [],
    store: [],
  },
  reducers: {
    setLoading(state, action){
      return {...state, loadingFor: action.payload}
    },
    setMakes(state, action){
      return {...state, make: action.payload}
    },
    setModels(state, action){
      return {...state, model: action.payload}
    },
    setFeatures(state, action){
      return {...state, features: action.payload}
    },
    setExteriorColors(state, action){
      return {...state, color_exterior: action.payload}
    },
    setInteriorColors(state, action){
      return {...state, color_interior: action.payload}
    },
    setType(state, action){
      return {...state, type: action.payload}
    },
    setTransmissionType(state, action){
      return {...state, transmission: action.payload}
    },
    setDrive(state, action){
      return {...state, drive: action.payload}
    },
    setSize(state, action){
      return {...state, size: action.payload}
    },
    setStore(state, action){
      return {...state, store: action.payload}
    },
    setCylinders(state, action){
      return {...state, cylinders: action.payload}
    },
    setMasterData(state, action) {
      state = action.payload
      return {...state}
    },
    setPrice(state, action){
      return {...state, price: action.payload}
    },
    setYear(state, action){
      return {...state, year: action.payload}
    },
    setMileage(state, action){
      return {...state, mileage: action.payload}
    }
  }
})

const masterDataActions = {
  ...masterData.actions
}

export {masterDataActions}

export default masterData.reducer