import { createSlice } from "@reduxjs/toolkit";

export const user = createSlice({
  name: 'user',
  initialState: {
    userDetails: {},
    assignedStore: null,
    promptStoreSelection: false,
  },
  reducers: {
    setUser(state, action) {
      return {...state, userDetails: action.payload}
    },
    setAssignedStore(state, action){
      return {...state, assignedStore: action.payload}
    },
    setPromptStoreSelection(state, action){
      return {...state, promptStoreSelection: action.payload}
    }
  }
})

const userActions = {
  ...user.actions
}

export {userActions}

export default user.reducer