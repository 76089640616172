import { Alert, AlertTitle, Snackbar } from '@mui/material'
import React from 'react'

const AlertBar = ({onClose, title, message, AlertProps}) => {
  return (
    <Snackbar open={true} autoHideDuration={6000} onClose={onClose}>
        <Alert {...AlertProps}>
          <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
      </Snackbar>
  )
}

export default AlertBar