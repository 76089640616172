import _ from "lodash";
import {createSlice, createEntityAdapter} from "@reduxjs/toolkit";
import {getCarDetails} from "../../Services/carDetailsService/carDetailsService"
import carListSlice from "./carListSlice";


const carDetailsAdapter = createEntityAdapter({});

const carDetailsSlice = createSlice({
    name: "carDetails",
    initialState: carDetailsAdapter.getInitialState({
        busy: false,
    }),
    reducers:{
        setAll: carDetailsAdapter.setAll,
        addOne: carDetailsAdapter.addOne,
        addMany: carDetailsAdapter.addMany,
        updateOne: carDetailsAdapter.updateOne,
        updateMany: carDetailsAdapter.updateMany,
        upsertOne: carDetailsAdapter.upsertOne,
        upsertMany: carDetailsAdapter.upsertMany,
        removeOne: carDetailsAdapter.removeOne,
        removeMany: carDetailsAdapter.removeMany,
        removeAll: carDetailsAdapter.removeAll,
        setBusy: (state, action)=>{
            let busy = action.payload;
            if(busy === true){
                state.busy = true
            } 
            else{
                state.busy = false
            }
        }
    }
});

const fetchCarDetails = (car_id, setCarDetails) => async(dispatch)=>{

    dispatch(carDetailsSlice.actions.setBusy(true));
    try{
        const resData = await getCarDetails(car_id);
        setCarDetails(resData.results[0])

        //TODO get data using loadash
        const singleCar = _.get(resData, 'results') || [];
        dispatch(carDetailsSlice.actions.setAll(singleCar))
    }catch(err){
        dispatch(carDetailsSlice.actions.setAll({}));
    }
    dispatch(carDetailsSlice.actions.setBusy(false));

}


const actions = {
    ...carDetailsSlice.actions,
    fetchCarDetails
}


const selectors = {
    ...carDetailsAdapter.getSelectors((state)=> state.carDetails),
}

export {actions, selectors };

export default carDetailsSlice.reducer;